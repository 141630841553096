import axios from 'axios';
import authHeader from './auth-header';

class ProviderService {
    getProviders() {
        return axios.get(process.env.VUE_APP_API_URL + 'providers/list', { 
            headers: authHeader(),
        })
        // .then(console.log).catch(console.error)
        ;
    }

    getProvider($providerId) {
        return axios.get(process.env.VUE_APP_API_URL + 'provider/view/'+$providerId, { 
            headers: authHeader(),
        })
        // .then(console.log).catch(console.error)
        ;
    }

    providerCreate(providerData) {
        return axios.post(
            process.env.VUE_APP_API_URL + 'provider/create', //url
            providerData, //params
            { //options
                withCredentials: false,
                headers: authHeader(),
            }
        )
        // .then(console.log).catch(console.error)
        ;
    }

    assignToProduct(formData) {
        return axios.post(
            process.env.VUE_APP_API_URL + 'products/assignprovider', //url
            formData, //params
            { //options
                withCredentials: false,
                headers: authHeader(),
            }
        )
        // .then(console.log).catch(console.error)
        ;
    }

    assignToIngredient(formData) {
        return axios.post(
            process.env.VUE_APP_API_URL + 'ingredient/assignprovider', //url
            formData, //params
            { //options
                withCredentials: false,
                headers: authHeader(),
            }
        )
        // .then(console.log).catch(console.error)
        ;
    }

    setProviderBaseToIngredient(formData) {
        return axios.post(
            process.env.VUE_APP_API_URL + 'ingredient/set-provider-base', //url
            formData, //params
            { //options
                withCredentials: false,
                headers: authHeader(),
            }
        )
        // .then(console.log).catch(console.error)
        ;
    }

    setProviderBaseToProduct(formData) {
        return axios.post(
            process.env.VUE_APP_API_URL + 'product/set-provider-base', //url
            formData, //params
            { //options
                withCredentials: false,
                headers: authHeader(),
            }
        )
        // .then(console.log).catch(console.error)
        ;
    }
}

export default new ProviderService();
